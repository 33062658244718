import React from "react"
import { Helmet } from "react-helmet"

import "@styles/bootstrap.css"
import "@styles/animate.css"
import "@styles/style.css"
import "@styles/media.css"
import "@styles/colors/blue.css"

export default function Layout({ children, title, pageClass }) {
  const pageClassses = `page-container ${pageClass}`

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
      </Helmet>
      <main>
        <div className={pageClassses}>
          <div className="bg_pattern"></div>
          <div id="container" className="wrapper">
            <div id="fittext-page-container">
              <div className="decal"></div>
              {children}
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
