import React from "react"

export default function ContentWrapper({ children }) {
  return (
    <>
      <div className="page-text" id="fittext-home">
        <div className="container">{children}</div>
      </div>
    </>
  )
}
